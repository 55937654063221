import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import pageContentQuery from './PageContentQuery.gql';

const PageContent = ({ children, pageId }) => {
  return (
    <Fragment>
      <Query query={pageContentQuery} variables={{ id: pageId }}>
        {result => {
          const { data } = result;
          const page = data && data.page;
          return children(page);
        }}
      </Query>
    </Fragment>
  );
};

export default PageContent;
