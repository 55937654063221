import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import PageContent from './Util/PageContent';
import TextContent from './ui/TextContent';
import { ContentRender } from './ContentRender/ContentRender';
import AddwishRow from './ui/AddwishRow/AddwishRow';
import SiteContentContext from './SiteContent/SiteContentContext';
import { useLocation } from 'react-router';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useAddwish } from '@jetshop/flight-addwish';
import { getChannel } from '../shop.config.custom';

const ErrorContainer = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 4vh 2rem;
    align-items: center;
`;

const ErrorTitle = styled('h1')`
    margin: 0 0 1rem 0 !important;
`;

const ErrorBody = styled('div')`
    color: #555;
    max-width: 700px;
    padding-bottom: 1em;
`;


const AddwishBoxes = () => {

  const location = useLocation();
  const { selectedChannel } = useContext(ChannelContext);
  const url = selectedChannel.url + location.pathname;
  const { retargetTitle, retargetId, topProductsTitle, topProductsId } =
    useContext(SiteContentContext);

  //ADDWISH
  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [retargetId]: {
        url: url
      },
      [topProductsId]: {
        url: url
      }
    },
    pageLoaded: true
  });

  return (
    <>
      <AddwishRow
        data={addWishResponseBoxItems[retargetId]}
        id={retargetId}
        slider={true}
        title={retargetTitle}
      />
      <AddwishRow
        data={addWishResponseBoxItems[topProductsId]}
        id={topProductsId}
        slider={true}
        title={topProductsTitle}
      />
    </>
  );
};


const NotFoundPage = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const { addwishActive } = getChannel(selectedChannel.name);

  return (
    <PageContent pageId={118}>
      {(page) => (
        <>
          <ErrorContainer>
            <ErrorTitle>{page && page.name}</ErrorTitle>
            <ErrorBody>
              {page?.content && <TextContent content={page.content} />}
            </ErrorBody>
          </ErrorContainer>
          {page?.data && <ContentRender data={page.data} />}
          {addwishActive && <AddwishBoxes />}
        </>
      )}
    </PageContent>
  );
};

export default NotFoundPage;
